.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader:empty {
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 2em);
  width: 3em;
  height: 3em;
  border: 0.6em solid rgba(0, 0, 0, 0.25);
  border-left: 0.6em solid #000000;
  border-radius: 50%;
  animation: loader-spinner 1s infinite linear;
}

@keyframes loader-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
